<template>
    <div id="nvSnapshotOverview">
        <v-card flat>
            <v-card-text>
                <div v-if="fetchingSpaces">
                    <v-skeleton-loader v-for="n in 2" :key="n" type="list-item"></v-skeleton-loader>
                </div>
                <div v-else>
                    <div class="font-weight-bold primary--text d-flex align-center justify-space-between">
                        <TheSpaceEditableNameField v-if="isSpaceAdmin === true"></TheSpaceEditableNameField>
                        <span v-else-if="isSpaceAdmin === false" class="primary--text title font-weight-bold">{{
                            $store.getters['spaceStore/spaceLongNameById']($route.params.sid)
                        }}</span>
                        <div class="d-flex align-center">
                            <span v-if="isSpaceAdmin === true" class="primary--text">
                                <v-icon color="primary" left>
                                    security
                                </v-icon>
                                Admin
                            </span>
                            <v-menu v-if="currentSpaceType !== spaceTypes.VENDOR_SPACE" :close-on-content-click="true" offset-y bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="primary" icon dark class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav>
                                    <v-list-item v-if="isSpaceAdmin === true || isOrgManager === true || (isSpaceAdmin === false && isOrgManager === false)">
                                        <v-list-item-title>
                                            <LeaveDialog
                                                :levelLeavingTitle="
                                                    currentSpaceType === spaceTypes.EDUCATION_SPACE
                                                        ? 'leave course'
                                                        : currentSpaceType === spaceTypes.RESEARCH_SPACE
                                                        ? 'leave project'
                                                        : 'leave space'
                                                "
                                                hierarchicalLevel="space"
                                            />
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="isSpaceAdmin === false && isOrgManager === false">
                                        <v-list-item-title>
                                            <LeaveDialog levelLeavingTitle="leave instance" hierarchicalLevel="instance" />
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                    <div>
                        <span v-if="isSpaceAdmin === false" class="subtitle-2 primary--text">{{
                            $store.getters['spaceStore/spaceDescriptionById'](this.$route.params.sid)
                        }}</span>
                        <TheSpaceEditableDescriptionField v-else-if="isSpaceAdmin === true"></TheSpaceEditableDescriptionField>
                    </div>
                </div>
                <div>
                    <div class="mt-3 mb-2" />
                    <div class="d-flex align-center">
                        <v-btn
                            text
                            class="caption px-1"
                            :to="{
                                name: 'instance-snapshots',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                            ><v-icon class="pr-1">timeline</v-icon>Timeline</v-btn
                        >
                        <v-btn
                            text
                            v-if="isDevelopment === true && isInstanceEditor === true"
                            :to="{
                                name: 'snapshot-add-snapshot',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                            class="caption px-1"
                            ><v-icon small>add</v-icon>Create snapshot</v-btn
                        >
                        <v-spacer />
                        <v-chip class="caption" small>
                            <v-icon v-if="isDevelopment" small class="pr-1">build</v-icon><v-icon v-else class="pr-1" small>camera_alt</v-icon
                            >{{ snapshotName }}</v-chip
                        >
                        <span v-if="isDevelopment === false && currentSnapshotData" class="pl-2">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-chip v-on="on" small outlined class="caption">
                                        <v-icon v-on="on" :color="currentSnapshotData.filesystem_prefix ? 'success' : 'info'" small>{{
                                            getSnapshotStatusIcon(currentSnapshotData)
                                        }}</v-icon>
                                        Immutable</v-chip
                                    >
                                </template>
                                <ul style="text-decoration:null">
                                    <li>In an immutable state, it is only possible to view and share objects (files, tables, and applications).</li>
                                    <li>{{ getSnapshotStatusDescription(currentSnapshotData) }}</li>
                                </ul>
                            </v-tooltip>
                        </span>
                        <v-btn
                            text
                            width="10"
                            :to="{
                                name: 'snapshot-overview',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: prevSnapshot }
                            }"
                            :disabled="!prevSnapshot"
                            class="mx-1"
                            small
                            ><v-icon>chevron_left</v-icon></v-btn
                        >
                        <v-btn
                            text
                            width="10"
                            small
                            :to="{
                                name: 'snapshot-overview',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: nextSnapshot }
                            }"
                            :disabled="!nextSnapshot"
                            ><v-icon>chevron_right</v-icon></v-btn
                        >
                    </div>
                    <v-progress-linear height="4" :value="snapshotProgress" color="secondary" class="mt-2 mb-2"></v-progress-linear>

                    <div class="mt-3 mb-2" />
                </div>
                <v-row>
                    <v-col md="8">
                        <v-card flat outlined style="min-height: 430px" :color="readme_md_content === '' && !fetchingReadme ? 'sidebarLight' : ''">
                            <v-card-text>
                                <div class="d-flex justify-end">
                                    <TheSnapshotFileEditor
                                        v-if="isDevelopment && readmeData"
                                        :endpoint="`/snapshots/${$route.params.snid}/fs/files`"
                                        :fileData="readmeData"
                                        language="markdown"
                                        @readmeFileUpdated="fetchReadmeAndVideos($route.params.snid)"
                                    ></TheSnapshotFileEditor>
                                </div>
                                <div v-if="failedToFetchReadme" class="d-flex align-center text-center" style="min-height: 400px">
                                    This snapshot is in cold state and its files cannot be directly accessed from the web interface. Please restore the snapshot
                                    to the Current State to work with files.
                                </div>
                                <div v-else-if="readme_md_content !== ''" v-html="compiledMarkdown" class="markdown-body"></div>
                                <div v-else-if="!fetchingReadme" class="d-flex flex-column justify-center align-center" style="min-height: 400px">
                                    <p>No README for this state.</p>
                                    <TheSnapshotFileEditor
                                        v-if="isDevelopment"
                                        :isCreateReadmeFile="true"
                                        :endpoint="`/snapshots/${$route.params.snid}/fs/files`"
                                        language="markdown"
                                        :newReadmeFileContent="newReadmeFileContent"
                                        @readmeFileCreated="fetchReadmeAndVideos($route.params.snid)"
                                    ></TheSnapshotFileEditor>
                                </div>
                                <div v-else>
                                    <v-skeleton-loader type="article@2"></v-skeleton-loader>
                                    <v-skeleton-loader type="paragraph" class="ml-4"></v-skeleton-loader>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="4">
                        <v-card flat>
                            <v-card-title>
                                <div style="width:100%" class="d-flex justify-space-between align-center">
                                    <h5 class="primary--text">About</h5>
                                    <v-chip small v-if="!isInstanceEditor" outlined class="ml-1 primary--text">
                                        <v-icon small color="primary" left>
                                            visibility
                                        </v-icon>
                                        Viewer
                                    </v-chip>
                                </div>
                            </v-card-title>
                            <v-card-text class="pa-0">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon>group</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <TheInstanceEditableNameField v-if="isInstanceEditor === true"></TheInstanceEditableNameField>
                                        <span v-else-if="isInstanceEditor === false" class="primary--text subtitle-2">{{ instanceName }}</span>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon>description</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <TheInstanceEditableDescriptionField v-if="isInstanceEditor === true"></TheInstanceEditableDescriptionField>
                                        <span class="primary--text subtitle-2" v-else-if="isInstanceEditor === false">{{ currentInstanceDescription }}</span>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-alert
                                    type="info"
                                    dismissible
                                    max-width="400"
                                    v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE && isMasterInstance(instanceName) === true"
                                >
                                    Material in the master instance is not directly visible to students, it has to be distributed.
                                    <div class="d-flex justify-end">
                                        <v-btn
                                            outlined
                                            href="https://docs.nuvolos.cloud/education/instructor-topics/instructor-guide#distribute-to-prospective-students"
                                            target="_blank"
                                            small
                                            >Learn more</v-btn
                                        >
                                    </div>
                                </v-alert>
                            </v-card-text>
                        </v-card>
                        <v-divider class="my-3" v-if="videoURLs" />
                        <v-card flat v-if="videoURLs">
                            <v-card-title>
                                <div class="d-flex justify-space-between">
                                    <h5 class="primary--text">Videos</h5>
                                    <v-btn color="secondary" small text @click="showVideoModal">See All</v-btn>
                                </div>
                            </v-card-title>
                            <v-card-text class="pa-0">
                                <div id="nvVideoPreview">
                                    <iframe
                                        :src="videoURLs[videoURLs.length - 1].url"
                                        :style="{ width: '100%', 'background-color': 'black', height: videoHeight + 'px' }"
                                        frameborder="0"
                                        allow="autoplay; fullscreen"
                                        allowfullscreen
                                        v-resize="onResize"
                                    ></iframe>
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-divider class="my-3" />
                        <v-card flat>
                            <v-card-title>
                                <h5 class="primary--text">Resources</h5>
                            </v-card-title>
                            <v-card-text class="pa-0">
                                <v-list-item
                                    :to="{
                                        name: 'snapshot-files',
                                        params: {
                                            oid: $route.params.oid,
                                            sid: $route.params.sid,
                                            iid: $route.params.iid,
                                            snid: $route.params.snid,
                                            fileArea: fileAreaType,
                                            localPath: currentFileLocalPath
                                        }
                                    }"
                                    :two-line="screenSize === 'large' || screenSize === 'small'"
                                    :three-line="screenSize === 'medium'"
                                >
                                    <v-list-item-avatar>
                                        <v-icon large>folder</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 class="mr-2 primary--text">Files</h4>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption mr-3 font-weight-bold">Browse and manage files</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item
                                    :to="{
                                        name: 'snapshot-tables',
                                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                    }"
                                    :two-line="screenSize === 'large' || screenSize === 'small'"
                                    :three-line="screenSize === 'medium'"
                                >
                                    <v-list-item-avatar>
                                        <v-icon large>mdi-table-large</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 class="mr-2 primary--text">Tables</h4>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption mr-3 font-weight-bold">Query and export data</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    :to="{
                                        name: 'snapshot-applications',
                                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                    }"
                                    :two-line="screenSize === 'large' || screenSize === 'small'"
                                    :three-line="screenSize === 'medium'"
                                >
                                    <v-list-item-avatar>
                                        <v-icon large>desktop_windows</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 class="mr-2 primary--text">Applications</h4>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption font-weight-bold">Create and launch applications</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    :to="{
                                        name: 'instance-snapshots',
                                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                    }"
                                    :two-line="screenSize === 'large' || screenSize === 'small'"
                                    :three-line="screenSize === 'medium'"
                                >
                                    <v-list-item-avatar>
                                        <v-icon large>camera_alt</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 class="mr-2 primary--text">Snapshots</h4>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="caption"
                                                >Review timeline of saved course versions</span
                                            >
                                            <span v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE" class="caption"
                                                >Review timeline of saved project versions</span
                                            >
                                            <span v-if="currentSpaceType === spaceTypes.VENDOR_SPACE" class="caption"
                                                >Review timeline of saved dataset versions</span
                                            >
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                        <v-divider class="my-3" />
                        <v-card flat>
                            <v-card-title>
                                <h5 class="primary--text">Manage</h5>
                            </v-card-title>
                            <v-card-text class="pa-0">
                                <v-list-item
                                    :to="{
                                        name: 'snapshot-distribute',
                                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                    }"
                                    :two-line="screenSize === 'large' || screenSize === 'small'"
                                    :three-line="screenSize === 'medium'"
                                >
                                    <v-list-item-avatar>
                                        <v-icon large>share</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 class="mr-2 primary--text">Staged Objects</h4>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span v-if="currentSpaceType === spaceTypes.VENDOR_SPACE" class="caption font-weight-bold"
                                                >Selectively send files, tables and apps</span
                                            >
                                            <span v-else class="caption font-weight-bold">Selectively share files, tables and apps</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="isSpaceAdmin === true"
                                    :to="{
                                        name: 'space-user-management',
                                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                    }"
                                    :two-line="screenSize === 'large' || screenSize === 'small'"
                                    :three-line="screenSize === 'medium'"
                                >
                                    <v-list-item-avatar>
                                        <v-icon large>mdi-account-group-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="mr-2 primary--text">Course Users</h4>
                                            <h4 v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE" class="mr-2 primary--text">Project Users</h4>
                                            <h4 v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE" class="mr-2 primary--text">Dataset Users</h4>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption mr-3">Manage roles, instances and invitations.</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <ReportingDashboard v-if="isSpaceAdmin === true" :screenSize="screenSize"></ReportingDashboard>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="showVideos" width="60vw">
            <v-card>
                <v-card-title>Videos</v-card-title>
                <v-card-text>
                    <v-carousel v-model="videoModel" v-if="videoURLs" height="55vh" hide-delimiters>
                        <v-carousel-item v-for="(video, i) in videoURLs" :key="video.url">
                            <div class="mb-2">{{ video.description }}</div>
                            <div class="d-flex justify-center">
                                <iframe
                                    :src="videoURLs[i].url"
                                    :style="{ width: '50vw', 'background-color': 'black', height: '50vh' }"
                                    frameborder="0"
                                    allow="autoplay; fullscreen"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </v-carousel-item>
                    </v-carousel>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { snapshotEnums } from '@/mixins/snapshot'
import { isEditorRole, isViewerRole, isMasterInstance, isCurrentState } from '@/utils'
import * as marked from 'marked'
import * as dompurify from 'dompurify'
import highlight from 'highlight.js'
import 'github-markdown-css/github-markdown.css'
const TheSpaceEditableDescriptionField = () => import('@/modules/space/components/TheSpaceEditableDescriptionField')
const TheSpaceEditableNameField = () => import('@/modules/space/components/TheSpaceEditableNameField')
const TheInstanceEditableDescriptionField = () => import('@/modules/instance/components/TheInstanceEditableDescriptionField')
const TheInstanceEditableNameField = () => import('@/modules/instance/components/TheInstanceEditableNameField')
const LeaveDialog = () => import('@/components/LeaveDialog')
const ReportingDashboard = () => import('@/components/ReportingDashboardDialog')
const TheSnapshotFileEditor = () => import('../components/TheSnapshotFileEditor')

export default {
    mixins: [enumsData, snapshotEnums],
    components: {
        TheSpaceEditableDescriptionField,
        TheSpaceEditableNameField,
        TheInstanceEditableDescriptionField,
        TheInstanceEditableNameField,
        LeaveDialog,
        ReportingDashboard,
        TheSnapshotFileEditor
    },
    data() {
        return {
            getStarted: [0, 1, 2],
            fab: false,
            overviewTab: 0,
            readme_md_content: '',
            fetchingReadme: true,
            settingsModal: false,
            videoHeight: 200,
            videoURLs: null,
            showVideos: false,
            videoModel: 0,
            readmeData: null,
            failedToFetchReadme: false
        }
    },
    computed: {
        ...mapGetters('instanceStore', ['isInstanceEditor', 'currentInstanceDescription']),
        ...mapState('instanceStore', ['instanceSnapshots']),
        ...mapState('orgStore', ['spacesWithPrimarySnapshots', 'fetchingSpaces']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath', 'isDevelopment', 'currentSnapshotData']),
        ...mapState('spaceStore', ['spaceInstances']),
        ...mapState(['currentOrgData']),
        ...mapState('snapshotStore', ['fileAreaType']),
        ...mapGetters('orgStore', ['isOrgManager', 'isOrgFaculty']),
        snapshotDescription() {
            return this.$store.getters['snapshotStore/snapshotDescriptionById'](this.$route.params.snid)
        },
        snapshotName() {
            return this.$store.getters['snapshotStore/snapshotLongNameById'](this.$route.params.snid)
        },
        newReadmeFileContent() {
            const spaceName = this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid)
            const spaceDescription = this.$store.getters['spaceStore/spaceDescriptionById'](this.$route.params.sid)
            const content = `<!-- customize below -->\n<!-- for syntax see https://www.markdownguide.org/basic-syntax/ -->\n\n # ${spaceName}\n\n ## About\n ${spaceDescription}`
            return content
        },
        screenSize() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return 'small'
            } else if (this.$vuetify.breakpoint.mdAndDown) {
                return 'medium'
            } else if (this.$vuetify.breakpoint.lgAndup) {
                return 'large'
            }
            return 'large'
        },
        instanceRole() {
            if (this.$route.params.iid && this.spaceInstances) {
                const currentInstance = this.spaceInstances.filter(instance => instance.iid.toString() === this.$route.params.iid.toString())
                if (currentInstance.length) {
                    if (isEditorRole(currentInstance[0].role) === true) {
                        return 'Instance editor'
                    } else if (isViewerRole(currentInstance[0].role) === true) {
                        return 'Instance viewer'
                    } else {
                        return currentInstance[0].role
                    }
                } else {
                    return 'Unknown instance role'
                }
            }
            return null
        },
        instanceName() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        },
        compiledMarkdown() {
            if (this.$data.readme_md_content) {
                return dompurify.sanitize(
                    marked(this.$data.readme_md_content, {
                        highlight(md) {
                            return highlight.highlightAuto(md).value
                        }
                    }),
                    {
                        ADD_TAGS: ['iframe'],
                        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
                    }
                )
            } else {
                return ''
            }
        },
        userSnapshotsBasedOnRole() {
            if (this.instanceSnapshots && this.isInstanceEditor === true) {
                return this.instanceSnapshots
            } else if (this.instanceSnapshots && this.isInstanceEditor === false) {
                return this.instanceSnapshots.filter(snapshot => isCurrentState(snapshot.short_id) === false)
            }
            return []
        },
        snapshotProgress() {
            if (this.isDevelopment) {
                return 100
            } else {
                const isBefore = this.userSnapshotsBasedOnRole
                    .map(s => {
                        return parseInt(s.snid) <= parseInt(this.$route.params.snid) ? 1 : 0
                    })
                    .reduce((a, b) => a + b, 0)
                return Math.floor((100 * (isBefore - 1)) / this.userSnapshotsBasedOnRole.length)
            }
        },
        nextSnapshot() {
            if (this.isDevelopment) {
                return null
            } else if (this.userSnapshotsBasedOnRole) {
                const largerSNID = this.userSnapshotsBasedOnRole.filter(s => {
                    return parseInt(s.snid, 10) > parseInt(this.$route.params.snid, 10)
                })
                if (largerSNID.length) {
                    return largerSNID.map(s => parseInt(s.snid, 10)).reduce((a, b) => Math.min(a, b), largerSNID[0].snid)
                } else if (this.isInstanceEditor) {
                    const developmentSnapshot = this.userSnapshotsBasedOnRole.find(s => isCurrentState(s.short_id))
                    return developmentSnapshot ? developmentSnapshot.snid : null
                } else {
                    return parseInt(this.$route.params.snid, 10)
                }
            }
            return null
        },
        prevSnapshot() {
            if (this.isDevelopment) {
                if (this.userSnapshotsBasedOnRole.length > 1) {
                    return this.userSnapshotsBasedOnRole.map(s => parseInt(s.snid, 10)).reduce((a, b) => Math.max(a, b))
                } else {
                    return null
                }
            } else {
                const smallerSNID = this.userSnapshotsBasedOnRole.filter(s => {
                    return parseInt(s.snid, 10) < parseInt(this.$route.params.snid, 10) && isCurrentState(s.short_id) === false
                })
                if (smallerSNID.length) {
                    return smallerSNID.map(s => parseInt(s.snid, 10)).reduce((a, b) => Math.max(a, b))
                } else {
                    return null
                }
            }
        }
    },
    watch: {
        '$route.params.snid': {
            handler: function(to, from) {
                this.fetchReadmeAndVideos(to)
            },
            immediate: true
        },
        compiledMarkdown: {
            handler: function(to, from) {
                this.$nextTick(() => {
                    const markdownBody = document.getElementById('nvSnapshotOverview').getElementsByClassName('markdown-body')[0]
                    if (markdownBody) {
                        const aTags = markdownBody.querySelectorAll('a')
                        aTags.forEach(function(anchorTag) {
                            const href = anchorTag.getAttribute('href')
                            if (!href.startsWith('http') && !href.startsWith('mailto')) {
                                anchorTag.setAttribute('href', 'files/' + href)
                            }
                        })
                    }
                })
            }
        }
    },
    methods: {
        onResize() {
            this.$data.videoHeight = Math.round(document.getElementById('nvVideoPreview').offsetWidth * 0.75)
        },
        showVideoModal() {
            this.$data.showVideos = true
        },
        isMasterInstance(instanceName) {
            return isMasterInstance(instanceName)
        },
        fetchReadmeAndVideos(snid) {
            const baseURL = `/snapshots/${snid}/fs/files`
            this.$data.fetchingReadme = true
            this.$data.failedToFetchReadme = false
            this.$axios
                .get(baseURL)
                .then(response => {
                    var hasREADME = false
                    var hasVIDEOS = false
                    response.data.map(s => {
                        if (s.short_id.toLowerCase() === 'readme.md') {
                            hasREADME = true
                            this.$data.readmeData = s
                            this.$axios
                                .get(`/files/${s.fid}/download`, {
                                    timeout: 10000,
                                    responseType: 'blob' // important
                                })
                                .then(response => {
                                    var reader = new FileReader()
                                    reader.addEventListener('loadend', e => {
                                        const text = e.srcElement.result
                                        this.$data.readme_md_content = text
                                    })
                                    reader.readAsText(response.data)
                                })
                                .catch(e => {
                                    console.log(e)
                                    this.$data.readme_md_content = ''
                                })
                                .finally(() => {
                                    window.setTimeout(() => (this.$data.fetchingReadme = false), 1000)
                                })
                        }
                        if (s.short_id.toLowerCase() === '.videos.json') {
                            hasVIDEOS = true
                            this.$axios
                                .get(`/files/${s.fid}/download`, {
                                    timeout: 10000,
                                    responseType: 'blob' // important
                                })
                                .then(response => {
                                    var reader = new FileReader()
                                    reader.addEventListener('loadend', e => {
                                        const text = e.srcElement.result
                                        this.$data.videoURLs = JSON.parse(text).nvVIDEOS
                                        this.$nextTick(() => {
                                            this.$data.videoHeight = Math.round(document.getElementById('nvVideoPreview').offsetWidth * 0.75)
                                        })
                                    })
                                    reader.readAsText(response.data)
                                })
                                .catch(e => {
                                    console.log(e)
                                })
                        }
                    })
                    if (!hasREADME) {
                        this.$data.fetchingReadme = false
                        this.$data.readme_md_content = ''
                    }
                    if (!hasVIDEOS) {
                        this.$data.videoURLs = null
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.code === 'item_not_found') {
                        this.$data.failedToFetchReadme = true
                    }
                })
        }
    }
}
</script>
