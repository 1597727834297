var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "nvSnapshotOverview" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _vm.fetchingSpaces
                ? _c(
                    "div",
                    _vm._l(2, function(n) {
                      return _c("v-skeleton-loader", {
                        key: n,
                        attrs: { type: "list-item" }
                      })
                    }),
                    1
                  )
                : _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "font-weight-bold primary--text d-flex align-center justify-space-between"
                      },
                      [
                        _vm.isSpaceAdmin === true
                          ? _c("TheSpaceEditableNameField")
                          : _vm.isSpaceAdmin === false
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "primary--text title font-weight-bold"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$store.getters[
                                      "spaceStore/spaceLongNameById"
                                    ](_vm.$route.params.sid)
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _vm.isSpaceAdmin === true
                              ? _c(
                                  "span",
                                  { staticClass: "primary--text" },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "primary", left: "" } },
                                      [_vm._v(" security ")]
                                    ),
                                    _vm._v(" Admin ")
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentSpaceType !== _vm.spaceTypes.VENDOR_SPACE
                              ? _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-content-click": true,
                                      "offset-y": "",
                                      bottom: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      color: "primary",
                                                      icon: "",
                                                      dark: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("more_vert")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2816612581
                                    )
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      { attrs: { dense: "", nav: "" } },
                                      [
                                        _vm.isSpaceAdmin === true ||
                                        _vm.isOrgManager === true ||
                                        (_vm.isSpaceAdmin === false &&
                                          _vm.isOrgManager === false)
                                          ? _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  [
                                                    _c("LeaveDialog", {
                                                      attrs: {
                                                        levelLeavingTitle:
                                                          _vm.currentSpaceType ===
                                                          _vm.spaceTypes
                                                            .EDUCATION_SPACE
                                                            ? "leave course"
                                                            : _vm.currentSpaceType ===
                                                              _vm.spaceTypes
                                                                .RESEARCH_SPACE
                                                            ? "leave project"
                                                            : "leave space",
                                                        hierarchicalLevel:
                                                          "space"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isSpaceAdmin === false &&
                                        _vm.isOrgManager === false
                                          ? _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  [
                                                    _c("LeaveDialog", {
                                                      attrs: {
                                                        levelLeavingTitle:
                                                          "leave instance",
                                                        hierarchicalLevel:
                                                          "instance"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.isSpaceAdmin === false
                          ? _c(
                              "span",
                              { staticClass: "subtitle-2 primary--text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$store.getters[
                                      "spaceStore/spaceDescriptionById"
                                    ](this.$route.params.sid)
                                  )
                                )
                              ]
                            )
                          : _vm.isSpaceAdmin === true
                          ? _c("TheSpaceEditableDescriptionField")
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
              _c(
                "div",
                [
                  _c("div", { staticClass: "mt-3 mb-2" }),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "caption px-1",
                          attrs: {
                            text: "",
                            to: {
                              name: "instance-snapshots",
                              params: {
                                oid: _vm.$route.params.oid,
                                sid: _vm.$route.params.sid,
                                iid: _vm.$route.params.iid,
                                snid: _vm.$route.params.snid
                              }
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "pr-1" }, [
                            _vm._v("timeline")
                          ]),
                          _vm._v("Timeline")
                        ],
                        1
                      ),
                      _vm.isDevelopment === true &&
                      _vm.isInstanceEditor === true
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "caption px-1",
                              attrs: {
                                text: "",
                                to: {
                                  name: "snapshot-add-snapshot",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid
                                  }
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("add")
                              ]),
                              _vm._v("Create snapshot")
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-chip",
                        { staticClass: "caption", attrs: { small: "" } },
                        [
                          _vm.isDevelopment
                            ? _c(
                                "v-icon",
                                { staticClass: "pr-1", attrs: { small: "" } },
                                [_vm._v("build")]
                              )
                            : _c(
                                "v-icon",
                                { staticClass: "pr-1", attrs: { small: "" } },
                                [_vm._v("camera_alt")]
                              ),
                          _vm._v(_vm._s(_vm.snapshotName))
                        ],
                        1
                      ),
                      _vm.isDevelopment === false && _vm.currentSnapshotData
                        ? _c(
                            "span",
                            { staticClass: "pl-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                {
                                                  staticClass: "caption",
                                                  attrs: {
                                                    small: "",
                                                    outlined: ""
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        color: _vm
                                                          .currentSnapshotData
                                                          .filesystem_prefix
                                                          ? "success"
                                                          : "info",
                                                        small: ""
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getSnapshotStatusIcon(
                                                          _vm.currentSnapshotData
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" Immutable")
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    290196193
                                  )
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      staticStyle: { "text-decoration": "null" }
                                    },
                                    [
                                      _c("li", [
                                        _vm._v(
                                          "In an immutable state, it is only possible to view and share objects (files, tables, and applications)."
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getSnapshotStatusDescription(
                                              _vm.currentSnapshotData
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            text: "",
                            width: "10",
                            to: {
                              name: "snapshot-overview",
                              params: {
                                oid: _vm.$route.params.oid,
                                sid: _vm.$route.params.sid,
                                iid: _vm.$route.params.iid,
                                snid: _vm.prevSnapshot
                              }
                            },
                            disabled: !_vm.prevSnapshot,
                            small: ""
                          }
                        },
                        [_c("v-icon", [_vm._v("chevron_left")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            width: "10",
                            small: "",
                            to: {
                              name: "snapshot-overview",
                              params: {
                                oid: _vm.$route.params.oid,
                                sid: _vm.$route.params.sid,
                                iid: _vm.$route.params.iid,
                                snid: _vm.nextSnapshot
                              }
                            },
                            disabled: !_vm.nextSnapshot
                          }
                        },
                        [_c("v-icon", [_vm._v("chevron_right")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-progress-linear", {
                    staticClass: "mt-2 mb-2",
                    attrs: {
                      height: "4",
                      value: _vm.snapshotProgress,
                      color: "secondary"
                    }
                  }),
                  _c("div", { staticClass: "mt-3 mb-2" })
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "8" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticStyle: { "min-height": "430px" },
                          attrs: {
                            flat: "",
                            outlined: "",
                            color:
                              _vm.readme_md_content === "" &&
                              !_vm.fetchingReadme
                                ? "sidebarLight"
                                : ""
                          }
                        },
                        [
                          _c("v-card-text", [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end" },
                              [
                                _vm.isDevelopment && _vm.readmeData
                                  ? _c("TheSnapshotFileEditor", {
                                      attrs: {
                                        endpoint:
                                          "/snapshots/" +
                                          _vm.$route.params.snid +
                                          "/fs/files",
                                        fileData: _vm.readmeData,
                                        language: "markdown"
                                      },
                                      on: {
                                        readmeFileUpdated: function($event) {
                                          return _vm.fetchReadmeAndVideos(
                                            _vm.$route.params.snid
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.failedToFetchReadme
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center text-center",
                                    staticStyle: { "min-height": "400px" }
                                  },
                                  [
                                    _vm._v(
                                      " This snapshot is in cold state and its files cannot be directly accessed from the web interface. Please restore the snapshot to the Current State to work with files. "
                                    )
                                  ]
                                )
                              : _vm.readme_md_content !== ""
                              ? _c("div", {
                                  staticClass: "markdown-body",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.compiledMarkdown)
                                  }
                                })
                              : !_vm.fetchingReadme
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-center align-center",
                                    staticStyle: { "min-height": "400px" }
                                  },
                                  [
                                    _c("p", [
                                      _vm._v("No README for this state.")
                                    ]),
                                    _vm.isDevelopment
                                      ? _c("TheSnapshotFileEditor", {
                                          attrs: {
                                            isCreateReadmeFile: true,
                                            endpoint:
                                              "/snapshots/" +
                                              _vm.$route.params.snid +
                                              "/fs/files",
                                            language: "markdown",
                                            newReadmeFileContent:
                                              _vm.newReadmeFileContent
                                          },
                                          on: {
                                            readmeFileCreated: function(
                                              $event
                                            ) {
                                              return _vm.fetchReadmeAndVideos(
                                                _vm.$route.params.snid
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("v-skeleton-loader", {
                                      attrs: { type: "article@2" }
                                    }),
                                    _c("v-skeleton-loader", {
                                      staticClass: "ml-4",
                                      attrs: { type: "paragraph" }
                                    })
                                  ],
                                  1
                                )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("v-card-title", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between align-center",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("h5", { staticClass: "primary--text" }, [
                                  _vm._v("About")
                                ]),
                                !_vm.isInstanceEditor
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "ml-1 primary--text",
                                        attrs: { small: "", outlined: "" }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                              left: ""
                                            }
                                          },
                                          [_vm._v(" visibility ")]
                                        ),
                                        _vm._v(" Viewer ")
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [_c("v-icon", [_vm._v("group")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _vm.isInstanceEditor === true
                                        ? _c("TheInstanceEditableNameField")
                                        : _vm.isInstanceEditor === false
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "primary--text subtitle-2"
                                            },
                                            [_vm._v(_vm._s(_vm.instanceName))]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [_c("v-icon", [_vm._v("description")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _vm.isInstanceEditor === true
                                        ? _c(
                                            "TheInstanceEditableDescriptionField"
                                          )
                                        : _vm.isInstanceEditor === false
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "primary--text subtitle-2"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.currentInstanceDescription
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.currentSpaceType ===
                                _vm.spaceTypes.EDUCATION_SPACE &&
                              _vm.isMasterInstance(_vm.instanceName) === true
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        type: "info",
                                        dismissible: "",
                                        "max-width": "400"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " Material in the master instance is not directly visible to students, it has to be distributed. "
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-end" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                outlined: "",
                                                href:
                                                  "https://docs.nuvolos.cloud/education/instructor-topics/instructor-guide#distribute-to-prospective-students",
                                                target: "_blank",
                                                small: ""
                                              }
                                            },
                                            [_vm._v("Learn more")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.videoURLs
                        ? _c("v-divider", { staticClass: "my-3" })
                        : _vm._e(),
                      _vm.videoURLs
                        ? _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c("v-card-title", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between"
                                  },
                                  [
                                    _c("h5", { staticClass: "primary--text" }, [
                                      _vm._v("Videos")
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "secondary",
                                          small: "",
                                          text: ""
                                        },
                                        on: { click: _vm.showVideoModal }
                                      },
                                      [_vm._v("See All")]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c("v-card-text", { staticClass: "pa-0" }, [
                                _c("div", { attrs: { id: "nvVideoPreview" } }, [
                                  _c("iframe", {
                                    directives: [
                                      {
                                        name: "resize",
                                        rawName: "v-resize",
                                        value: _vm.onResize,
                                        expression: "onResize"
                                      }
                                    ],
                                    style: {
                                      width: "100%",
                                      "background-color": "black",
                                      height: _vm.videoHeight + "px"
                                    },
                                    attrs: {
                                      src:
                                        _vm.videoURLs[_vm.videoURLs.length - 1]
                                          .url,
                                      frameborder: "0",
                                      allow: "autoplay; fullscreen",
                                      allowfullscreen: ""
                                    }
                                  })
                                ])
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider", { staticClass: "my-3" }),
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("v-card-title", [
                            _c("h5", { staticClass: "primary--text" }, [
                              _vm._v("Resources")
                            ])
                          ]),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: {
                                      name: "snapshot-files",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid,
                                        fileArea: _vm.fileAreaType,
                                        localPath: _vm.currentFileLocalPath
                                      }
                                    },
                                    "two-line":
                                      _vm.screenSize === "large" ||
                                      _vm.screenSize === "small",
                                    "three-line": _vm.screenSize === "medium"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("folder")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "h4",
                                          { staticClass: "mr-2 primary--text" },
                                          [_vm._v("Files")]
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption mr-3 font-weight-bold"
                                          },
                                          [_vm._v("Browse and manage files")]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: {
                                      name: "snapshot-tables",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid
                                      }
                                    },
                                    "two-line":
                                      _vm.screenSize === "large" ||
                                      _vm.screenSize === "small",
                                    "three-line": _vm.screenSize === "medium"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-table-large")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "h4",
                                          { staticClass: "mr-2 primary--text" },
                                          [_vm._v("Tables")]
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption mr-3 font-weight-bold"
                                          },
                                          [_vm._v("Query and export data")]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: {
                                      name: "snapshot-applications",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid
                                      }
                                    },
                                    "two-line":
                                      _vm.screenSize === "large" ||
                                      _vm.screenSize === "small",
                                    "three-line": _vm.screenSize === "medium"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("desktop_windows")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "h4",
                                          { staticClass: "mr-2 primary--text" },
                                          [_vm._v("Applications")]
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              "Create and launch applications"
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: {
                                      name: "instance-snapshots",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid
                                      }
                                    },
                                    "two-line":
                                      _vm.screenSize === "large" ||
                                      _vm.screenSize === "small",
                                    "three-line": _vm.screenSize === "medium"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("camera_alt")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "h4",
                                          { staticClass: "mr-2 primary--text" },
                                          [_vm._v("Snapshots")]
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm.currentSpaceType ===
                                        _vm.spaceTypes.EDUCATION_SPACE
                                          ? _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  "Review timeline of saved course versions"
                                                )
                                              ]
                                            )
                                          : _vm.currentSpaceType ===
                                            _vm.spaceTypes.RESEARCH_SPACE
                                          ? _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  "Review timeline of saved project versions"
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.currentSpaceType ===
                                        _vm.spaceTypes.VENDOR_SPACE
                                          ? _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  "Review timeline of saved dataset versions"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "my-3" }),
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("v-card-title", [
                            _c("h5", { staticClass: "primary--text" }, [
                              _vm._v("Manage")
                            ])
                          ]),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: {
                                      name: "snapshot-distribute",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid
                                      }
                                    },
                                    "two-line":
                                      _vm.screenSize === "large" ||
                                      _vm.screenSize === "small",
                                    "three-line": _vm.screenSize === "medium"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("share")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "h4",
                                          { staticClass: "mr-2 primary--text" },
                                          [_vm._v("Staged Objects")]
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm.currentSpaceType ===
                                        _vm.spaceTypes.VENDOR_SPACE
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "Selectively send files, tables and apps"
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "Selectively share files, tables and apps"
                                                )
                                              ]
                                            )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.isSpaceAdmin === true
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        to: {
                                          name: "space-user-management",
                                          params: {
                                            oid: _vm.$route.params.oid,
                                            sid: _vm.$route.params.sid,
                                            iid: _vm.$route.params.iid,
                                            snid: _vm.$route.params.snid
                                          }
                                        },
                                        "two-line":
                                          _vm.screenSize === "large" ||
                                          _vm.screenSize === "small",
                                        "three-line":
                                          _vm.screenSize === "medium"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { large: "" } },
                                            [
                                              _vm._v(
                                                "mdi-account-group-outline"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm.currentSpaceType ===
                                            _vm.spaceTypes.EDUCATION_SPACE
                                              ? _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "mr-2 primary--text"
                                                  },
                                                  [_vm._v("Course Users")]
                                                )
                                              : _vm.currentSpaceType ===
                                                _vm.spaceTypes.RESEARCH_SPACE
                                              ? _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "mr-2 primary--text"
                                                  },
                                                  [_vm._v("Project Users")]
                                                )
                                              : _vm.currentSpaceType ===
                                                _vm.spaceTypes.VENDOR_SPACE
                                              ? _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "mr-2 primary--text"
                                                  },
                                                  [_vm._v("Dataset Users")]
                                                )
                                              : _vm._e()
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _c(
                                              "span",
                                              { staticClass: "caption mr-3" },
                                              [
                                                _vm._v(
                                                  "Manage roles, instances and invitations."
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isSpaceAdmin === true
                                ? _c("ReportingDashboard", {
                                    attrs: { screenSize: _vm.screenSize }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "60vw" },
          model: {
            value: _vm.showVideos,
            callback: function($$v) {
              _vm.showVideos = $$v
            },
            expression: "showVideos"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Videos")]),
              _c(
                "v-card-text",
                [
                  _vm.videoURLs
                    ? _c(
                        "v-carousel",
                        {
                          attrs: { height: "55vh", "hide-delimiters": "" },
                          model: {
                            value: _vm.videoModel,
                            callback: function($$v) {
                              _vm.videoModel = $$v
                            },
                            expression: "videoModel"
                          }
                        },
                        _vm._l(_vm.videoURLs, function(video, i) {
                          return _c("v-carousel-item", { key: video.url }, [
                            _c("div", { staticClass: "mb-2" }, [
                              _vm._v(_vm._s(video.description))
                            ]),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("iframe", {
                                  style: {
                                    width: "50vw",
                                    "background-color": "black",
                                    height: "50vh"
                                  },
                                  attrs: {
                                    src: _vm.videoURLs[i].url,
                                    frameborder: "0",
                                    allow: "autoplay; fullscreen",
                                    allowfullscreen: ""
                                  }
                                })
                              ]
                            )
                          ])
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }